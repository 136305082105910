var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate($event)}}},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.lang.label)+" Section")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":_vm.lang.value + '.page_name',"rules":"","name":"The Page Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","label":"Page Name","error":failed ? errors[0]: null,"name":"page_name","fou":""},model:{value:(_vm.formData.page_name),callback:function ($$v) {_vm.$set(_vm.formData, "page_name", $$v)},expression:"formData.page_name"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":_vm.lang.value + '.redirection_url',"rules":"required","name":"The Redirection Url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Redirection Url","name":"redirection_url","fou":""},model:{value:(_vm.formData.redirection_url),callback:function ($$v) {_vm.$set(_vm.formData, "redirection_url", $$v)},expression:"formData.redirection_url"}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }